import * as React from "react";
import { Helmet } from "react-helmet";
import Layout from "../layouts/index";
import { Link } from "gatsby";

import demo from "../images/reserved-demo.gif";
import payment from "../images/reserved-payment.png";
import step1 from "../images/step-1.png";
import step2 from "../images/step-2.png";
import step3 from "../images/step-3.png";

const features = [
  {
    id: "feature-reservations",
    title: "Reservations Online",
    summary: [
      "Integrate reservation widget in multiple webpages",
      "Add the reservation widget to other websites/social media pages",
      "Secured SSL encryption",
      "Integrate to multiple web/social media pages",
      "Configure the colours and text based on your branding",
      "Responsive design",
    ],
  },
  {
    id: "feature-manage",
    title: "Manage Reservations",
    summary: [
      "Unlimited access to VIP Portal",
      "Allows you to manage all reservations in one place",
      "Configure working days, hours and much more",
      "Diary view for restaurant with the day's schedule",
      "Ability to manage reservations and notes in Diary view",
      "Easy to use diary features",
    ],
  },
  {
    id: "feature-availability",
    title: "Manage Availability",
    summary: [
      "Add and manage table availability at any time",
      "Block dates and add exceptions for certain dates",
      "Highly configurable table and seating based availability",
    ],
  },
  {
    id: "feature-alerts",
    title: "Alerts and Notifications",
    summary: [
      "SMS and Email alerts",
      "Customise all SMS and email alerts sent to customer and restaurant",
      "Ability to send cancellation and amendment links",
    ],
  },
  {
    id: "feature-reports",
    title: "Reports and Analytics",
    summary: [
      "A friendly Dashboard presenting latest activity",
      "Reservation reports based on various criteria",
      "Separate reports for admin reservations",
    ],
  },
  {
    id: "feature-cost",
    title: "Cost Effective",
    summary: [
      "Practical and affordable subscription costs",
      "No hidden costs, no commissions and no surprises",
      "Low transaction charges for deposit payments",
    ],
  },
  {
    id: "feature-secure",
    title: "Secure",
    summary: [
      "Your customer data remains with you",
      "No data sharing with any third parties",
      "Build your own customer database",
    ],
  },
  {
    id: "feature-payments",
    title: "Payments Enabled",
    summary: [
      "Optionally allow customers to pay deposit for reservations",
      "All payments directly land in restaurant's gateway account",
      "Secure and standard Stripe gateway integration",
    ],
  },
];

// markup
export default function ReservedPage() {
  const [selectedFeatureId, setSelectedFeatureId] = React.useState(
    "feature-reservations"
  );

  function handleFeatureClick(featureId) {
    setSelectedFeatureId(featureId);
  }

  const featuresMarkup = features.map((feature) => (
    <div className="col" key={feature.id}>
      <button
        className={
          selectedFeatureId === feature.id
            ? "btn btn-feature active"
            : "btn btn-feature"
        }
        onClick={() => handleFeatureClick(feature.id)}
      >
        {feature.title}
      </button>
    </div>
  ));

  const featuresSummaryMarkup = features.map((feature) => (
    <div
      key={feature.id}
      className={
        selectedFeatureId === feature.id
          ? "feature-summary active"
          : "feature-summary"
      }
    >
      <div>
        <h3>{feature.title}</h3>
        <ul>
          {feature.summary.map((bullet, index) => (
            <li key={index}>{bullet}</li>
          ))}
        </ul>
      </div>
    </div>
  ));

  return (
    <Layout>
      <Helmet title="Add table reservations to your restaurant's website with VIP Dine" />
      <section className="section hero reserved-hero">
        <div className="container">
          <div className="row">
            <div className="col">
              <h1>
                Online table reservations with{" "}
                <span className="product-name yellow">Reserved</span>
              </h1>
              <p>Add table reservations feature to your web pages</p>
            </div>
          </div>
        </div>
        <div className="overlay"></div>
      </section>

      <section className="section">
        <div className="container">
          <div className="row reverse stretch">
            <div className="col image reserved-image"></div>

            <div className="col">
              <h2>
                <span className="product-name">Reserved</span>
              </h2>
              <p>
                A well designed reservations widget and web based management
                system that works. This B2B2C solution, a table reservation
                system is extremely easy to add to any website giving
                restaurants a flexible online reservation system that their
                customer can use with great ease.
              </p>
              <p>
                Restaurant business owners will have full control of their own
                table inventory and are able to manage reservations,
                availability and much more themselves. There is no aggregation
                of restaurants, no sharing of data. The reservation widgets are
                not heavily project our brand giving a 'own' feel about the
                reservations widget.
              </p>
            </div>
          </div>
        </div>
      </section>

      <div className="container hr">
        <div className="hr"></div>
      </div>

      <section className="section">
        <div className="container">
          <div className="row">
            <div className="col">
              <img src={demo} alt="" />
            </div>

            <div className="col">
              <h2>The reservations widget</h2>
              <p>
                A super friendly widget for customers that can be styled to meet
                your brand requirements. The superior user experience is coupled
                with swift confirmations via SMS text messages and email.
              </p>
              <p>
                You are completely in control the reservations and availability
                with the VIP portal.
              </p>
              <p>
                <span className="product-name">Reserved</span> keeps your
                customer's data private and secured. Only you have access your
                customer's information.
              </p>
            </div>
          </div>
        </div>
      </section>

      <div className="container hr">
        <div className="hr"></div>
      </div>

      <section className="section">
        <div className="container">
          <div className="row">
            <div className="col">
              <img src={payment} alt="" />
            </div>

            <div className="col">
              <h2>Collect Deposit Payments</h2>
              <p>
                Minimise no-shows by enabling deposit payments for reservations.
                Filter out the blockers who tend to change their mind later.
              </p>
              <p>
                <span className="product-name">Reserved</span> allows you to
                enable deposit payments for reservations via Stripe payment
                gateway. Deposits collected go straight into the restaurant's
                gateway account.
              </p>
              <p>
                Secured payments processing at affordable processing charge.
              </p>
            </div>
          </div>
        </div>
      </section>

      <div className="container hr">
        <div className="hr"></div>
      </div>

      <section className="section">
        <div className="container">
          <div className="row">
            <div className="col">
              <h2>Fully Featured Online Table Reservations</h2>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row stretch">
            <div className="col">
              <div className="row stretch features">{featuresMarkup}</div>
            </div>

            <div className="col">{featuresSummaryMarkup}</div>
          </div>
        </div>
      </section>

      <div className="container hr">
        <div className="hr"></div>
      </div>

      <section className="section">
        <div className="container">
          <div className="row">
            <div className="col">
              <h2>Start in a matter of minutes</h2>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row stretch">
            <div className="col text-center">
              <img className="step" src={step1} alt="" />
              <p>Subscribe to your package online</p>
            </div>

            <div className="col text-center">
              <img className="step" src={step2} alt="" />
              <p>Configure availability and your restaurant branding</p>
            </div>

            <div className="col text-center">
              <img className="step" src={step3} alt="" />
              <p>Simply add the widget code to your web pages</p>
            </div>
          </div>
        </div>
      </section>

      <div className="container hr">
        <div className="hr"></div>
      </div>

      <section className="section">
        <div className="container">
          <div className="row stretch">
            <div className="col text-center">
              <h2>Get started</h2>
              <p>
                Take a look at our pricing and then register to start today!
              </p>
              <Link className="btn btn-primary" to="/pricing">
                Pricing
              </Link>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}
